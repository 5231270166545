import {AnyAction} from 'redux'
import {
  CLOSE_MONTHLY_CALENDAR_EVENT,
  CLOSE_MONTHLY_CALENDAR_POPUP,
  INIT_CALENDAR,
  OPEN_MONTHLY_CALENDAR_EVENT,
  OPEN_MONTHLY_CALENDAR_POPUP,
} from '../actions/calendar-layout'
import {CalendarLayout} from '../types/state'

const defaultState: CalendarLayout = {
  referenceDate: null,
  borderWidth: 1,
  monthly: {
    selectedDate: null,
    selectedEventId: null,
  },
  weeks: [],
  fullMonthText: '',
  weekDayNames: [],
}

export const calendarLayout = (state = defaultState, action: AnyAction): CalendarLayout => {
  switch (action.type) {
    case INIT_CALENDAR:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: null,
          selectedEventId: null,
        },
        weekDayNames: action.payload.weekDayNames,
        weeks: action.payload.weeks,
        fullMonthText: action.payload.fullMonthText,
        referenceDate: action.payload.referenceDate,
      }
    case OPEN_MONTHLY_CALENDAR_POPUP:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: action.payload.date,
          selectedEventId: null,
        },
      }
    case CLOSE_MONTHLY_CALENDAR_POPUP:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: null,
        },
      }
    case OPEN_MONTHLY_CALENDAR_EVENT:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedDate: action.payload.date,
          selectedEventId: action.payload.eventId,
        },
      }
    case CLOSE_MONTHLY_CALENDAR_EVENT:
      return {
        ...state,
        monthly: {
          ...state.monthly,
          selectedEventId: null,
        },
      }
    default:
      return state
  }
}
