import {MembersModal} from '@wix/wix-events-commons-statics'
import {callAPI, createActions} from '../../commons/actions/data-action-helper'
import {getLanguage} from '../../commons/selectors/environment'
import {openModal} from '../../commons/services/modal'
import {GetState, StoreExtraArgs} from '../types/state'

export const PROMPT_LOGIN = createActions('PROMPT_LOGIN')
export const LOAD_MEMBERS_FOR_EVENTS = createActions('LOAD_MEMBERS_FOR_EVENTS')
export const MEMBER_LOGGED_IN = 'MEMBER_LOGGED_IN'

export const ensureLoggedIn = () => async (dispatch, getState: GetState, {wixCodeApi}: StoreExtraArgs) => {
  if (!wixCodeApi.user.currentUser.loggedIn) {
    return dispatch(promptLogin())
  }
  return true
}

export const promptLogin = () => (dispatch, getState: GetState) =>
  dispatch(callAPI(PROMPT_LOGIN, getLanguage(getState())))

export const loadMembersForEvents = () => (dispatch, getState: GetState) =>
  dispatch(
    callAPI(
      LOAD_MEMBERS_FOR_EVENTS,
      getState().events.map(event => event.id),
    ),
  )

export const openMembersModal = (eventId: string) => async (dispatch: Function) => {
  const loggedIn = await dispatch(ensureLoggedIn())
  if (loggedIn) {
    return dispatch(
      openModal({
        type: MembersModal.LIST,
        params: {
          eventId,
        },
      }),
    )
  }
}

export const addLoginListener = () => async (dispatch: Function, getState: GetState, {wixCodeApi}: StoreExtraArgs) => {
  wixCodeApi.user.onLogin(async user => {
    if (user.loggedIn) {
      dispatch({type: MEMBER_LOGGED_IN})
    }
  })
}
