import {IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {isDetailsPageEnabled} from '../../commons/selectors/site-settings'
import {navigateToDetailsPage} from '../../commons/services/navigation'
import {isEventRestricted} from '../selectors/events'
import {GetState} from '../types/state'
import {ensureLoggedIn} from './members'

export const NAVIGATE_TO_PAGE = 'NAVIGATE_TO_PAGE'
export const RSVP_BUTTON_CLICKED = 'RSVP_BUTTON_CLICKED'

export const navigateToPage = (event: wix.events.Event) => async (
  dispatch,
  getState: GetState,
  {wixCodeApi}: {wixCodeApi: IWixAPI},
) => {
  dispatch(rsvpButtonClicked(event))
  const state = getState()
  const detailsPageEnabled = isDetailsPageEnabled(state.siteSettings)
  const isRestricted = isEventRestricted(state, event)
  if (!isRestricted || (await dispatch(ensureLoggedIn()))) {
    await navigateToDetailsPage(event, detailsPageEnabled, wixCodeApi)
    dispatch(navigatedToPage(event))
  }
}

export const onLinkNavigatedToPage = (event: wix.events.Event) => async dispatch => {
  dispatch(rsvpButtonClicked(event))
  dispatch(navigatedToPage(event))
}

const rsvpButtonClicked = (event: wix.events.Event) => ({
  type: RSVP_BUTTON_CLICKED,
  payload: {event},
})

const navigatedToPage = (event: wix.events.Event) => ({
  type: NAVIGATE_TO_PAGE,
  payload: {event},
})
