import {WIDGET_TYPE, getLocale} from '@wix/wix-events-commons-statics'
import {SET_BASE_ENVIRONMENT} from '../../commons/actions/environment'
import {updateDates} from '../../commons/actions/dates'
import {INJECT_COMPONENT_DRAFT, UPDATE_COMPONENT_DRAFT} from '../actions/component'
import {State} from '../types/state'

export const datesMiddleware = store => next => action => {
  switch (action.type) {
    case SET_BASE_ENVIRONMENT: {
      const state: State = store.getState()
      const eventsList = state.events.length ? state.events : getAllDemoEvents(state)
      const locale = getLocale(action.payload.locale)
      store.dispatch(updateDates({eventsList, locale}))
      break
    }
    case INJECT_COMPONENT_DRAFT:
    case UPDATE_COMPONENT_DRAFT.SUCCESS: {
      store.dispatch(updateDates({eventsList: action.payload.component.events}))
      break
    }
    default:
      break
  }
  next(action)
}

const getAllDemoEvents = (state: State) => {
  return [...(state.demoEvents?.[WIDGET_TYPE.LIST] ?? []), ...(state.demoEvents?.[WIDGET_TYPE.SINGLE] ?? [])]
}
