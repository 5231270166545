import {IWidgetController, IWidgetControllerConfig, IWixAPI} from '@wix/native-components-infra/dist/src/types/types'
import {
  createUouBiMiddlewareWithBiParams,
  isRtlLanguage,
  BiParams,
  EVENTS_APP_ID,
} from '@wix/wix-events-commons-statics'
import {ExperimentsBag} from '@wix/wix-experiments'
import {bindActionCreators, Store, PreloadedState} from 'redux'
import {setBaseEnvironment, setFormFactor} from '../../commons/actions/environment'
import {Tabs} from '../../commons/enums'
import {initSentry, ErrorMonitor} from '../../commons/services/error-monitor'
import {importResources} from '../../commons/services/import-18n-resources'
import {getMultilingualInitialState} from '../../commons/services/multilingual'
import {getCurrentMemberId, getMembersAPI} from '../../commons/utils/members-api'
import {createReduxStore, subscribeToStateChanges} from '../../commons/utils/store'
import {getLanguage, isSSR} from '../../commons/utils/wix-code-api'
import {getComponentData, updateComponent} from '../actions/component'
import {closeAllEvents, getEvents, seeOtherEvents, setTab, shareEvent, toggleEventDetails} from '../actions/events'
import {internalNavigate, navigateToDetailsPage, navigateToNoUpcomingEventsUrl} from '../actions/navigation'
import {cancelRsvp} from '../actions/rsvp'
import {updateSettings} from '../actions/sdk'
import {downloadTicketsAction, getMyTickets} from '../actions/tickets'
import {resetToLiveView} from '../actions/view'
import * as eventsUouEvents from '../bi/uou-bi-events-map'
import {datesMiddleware} from '../middlewares/date'
import reducers from '../reducers'
import {createMembersPageFedopsLogger} from '../services/fedops'
import {Actions, MemberPageState, StoreExtraArgs} from '../types/state'
import {Api} from '../utils/api'
import {isResponsiveEditor} from '../../commons/selectors/environment'

const DSN = 'https://3b51444243c04c0e9e435b8945a85362@sentry.wixpress.com/309'

export async function createMembersPageController(
  controller: IWidgetControllerConfig,
  experiments: ExperimentsBag,
): Promise<IWidgetController> {
  const monitor = initSentry(controller, DSN)
  return {
    pageReady: monitor.withErrorBoundary(() => pageReady(controller, monitor, experiments)),
  }
}

const pageReady = async (controller: IWidgetControllerConfig, monitor: ErrorMonitor, experiments: ExperimentsBag) => {
  const {appParams, setProps, wixCodeApi, config} = controller
  const fedopsLogger = createMembersPageFedopsLogger(controller)
  const {staticsBaseUrl} = controller.appParams.baseUrls
  const language = getLanguage(wixCodeApi)
  const translationsPromise = importResources(['members-page'], language, staticsBaseUrl)
  const [viewedSiteMemberId, pageUrl] = await Promise.all([getSiteMemberId(controller), getPageUrl(controller)])

  const store = createStore(controller, experiments, viewedSiteMemberId, pageUrl)
  const actions = exportedActions(store, fedopsLogger.onAppLoaded, monitor)

  const [translations] = await Promise.all([
    translationsPromise,
    actions.getEvents(Tabs.UPCOMING),
    actions.getComponentData(isResponsiveEditor(config)),
  ])

  await store.dispatch(setBaseEnvironment() as any)
  const state = store.getState()

  setProps({
    state,
    actions,
    cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
    isRTL: isRtlLanguage(language),
    translations,
  })

  subscribeToStateChanges(controller, store)

  const ssr = isSSR(wixCodeApi)

  if (!ssr) {
    actions.getEvents(Tabs.PAST)
  }

  if (ssr) {
    fedopsLogger.onSSRPageReady()
  }
}

const createBiMiddleware = (biParams: BiParams) => [createUouBiMiddlewareWithBiParams(biParams, eventsUouEvents)]

const createStore = (controller: IWidgetControllerConfig, experiments: ExperimentsBag, viewedSiteMemberId, pageUrl) => {
  const serverApi = new Api(controller)

  const initialData: PreloadedState<MemberPageState> = {
    experiments,
    multilingual: getMultilingualInitialState(controller.wixCodeApi),
    user: {
      currentUserId: controller.wixCodeApi.user.currentUser.id,
      viewedSiteMemberId,
    },
  } as PreloadedState<MemberPageState>

  const middleware = [
    ...createBiMiddleware({
      wixCodeApi: controller.wixCodeApi,
      platformAPIs: controller.platformAPIs,
      appParams: controller.appParams,
      compId: controller.compId,
      user: {
        uid: controller.wixCodeApi.user.currentUser.id,
      },
    }),
    datesMiddleware,
  ]

  return createReduxStore<MemberPageState, StoreExtraArgs>({
    initialData,
    extraArguments: {
      wixCodeApi: controller.wixCodeApi,
      serverApi,
      compId: controller.compId,
      baseUrl: controller.appParams.baseUrls.baseUrl,
      pageUrl,
    },
    reducers,
    middleware,
  })
}

const getSiteMemberId = (controller: IWidgetControllerConfig) =>
  controller.appParams.baseUrls.siteMemberId || getViewedUserId(controller.wixCodeApi)

const getViewedUserId = async (wixCodeApi: IWixAPI) => {
  if (wixCodeApi.window.viewMode === 'Editor') {
    return ''
  }
  try {
    const membersApi = await getMembersAPI(wixCodeApi)
    return await membersApi.getViewedUser()
  } catch (e) {
    const currentMemberId = getCurrentMemberId(wixCodeApi)
    if (currentMemberId) {
      return currentMemberId
    }
    throw e
  }
}

const getPageUrl = async ({wixCodeApi}: IWidgetControllerConfig): Promise<string> => {
  const pageUrl = await wixCodeApi.site.getSectionUrl({sectionId: 'events', appDefinitionId: EVENTS_APP_ID})
  return pageUrl?.url ?? null
}

const exportedActions = (store: Store, appLoaded, monitor: ErrorMonitor): Actions => {
  const dispatchActions = {
    getComponentData,
    getEvents,
    toggleEventDetails,
    cancelRsvp,
    shareEvent,
    navigateToDetailsPage,
    internalNavigate,
    updateSettings,
    updateComponent,
    setTab,
    resetToLiveView,
    downloadTicketsAction,
    closeAllEvents,
    seeOtherEvents,
    setFormFactor,
    navigateToNoUpcomingEventsUrl,
    getMyTickets,
  }

  const actions: Actions = {
    ...bindActionCreators(dispatchActions, store.dispatch),
    appLoaded,
  }

  return monitor.bindActions(actions)
}
