import {getDayMonthText, getFullMonthText, getWeekDayNames, moment, Moment} from '@wix/events-moment-commons'
import {WEEKDAY, WEEK_LENGTH} from '@wix/wix-events-commons-statics'
import {TAB} from '../../commons/constants/navigation'
import {getFullLocale, isEditor, isMobile} from '../../commons/selectors/environment'
import {DesignSection, TextSection} from '../../commons/enums'
import {getCalendarWeekStartDay} from '../../commons/selectors/settings'
import {
  CalendarMonth,
  CalendarWeek,
  getCalendarDays,
  getCalendarDaysWithMultipleEvents,
  getCalendarDaysWithOneEvent,
} from '../selectors/calendar-layout'
import {getEvents} from '../selectors/events'
import {getComponentConfig} from '../selectors/settings'
import {GetState, State} from '../types/state'

export const OPEN_MONTHLY_CALENDAR_POPUP = 'OPEN_MONTHLY_CALENDAR_POPUP'
export const CLOSE_MONTHLY_CALENDAR_POPUP = 'CLOSE_MONTHLY_CALENDAR_POPUP'

export const INIT_CALENDAR = 'INIT_CALENDAR'

export const OPEN_MONTHLY_CALENDAR_EVENT = 'OPEN_MONTHLY_CALENDAR_EVENT'
export const CLOSE_MONTHLY_CALENDAR_EVENT = 'CLOSE_MONTHLY_CALENDAR_EVENT'

export const addCalendarMonth = () => (dispatch, getState: GetState) => {
  const referenceDate = getReferenceDate(getState())
    .clone()
    .startOf('month')
    .add(1, 'month')
  dispatch(initCalendarLayout(referenceDate))
}

export const subCalendarMonth = () => (dispatch, getState: GetState) => {
  const referenceDate = getReferenceDate(getState())
    .clone()
    .startOf('month')
    .subtract(1, 'month')
  dispatch(initCalendarLayout(referenceDate))
}

export const resetCalendar = () => (dispatch, getState: GetState) => {
  const state = getState()

  if (isEditor(state)) {
    dispatch(initCalendarLayout(getShowcaseReferenceDate(state)))
  } else {
    dispatch(initCalendarLayout(moment().startOf('day')))
  }
}

export const openMonthlyCalendarPopup = (timestamp: number) => (dispatch, getState: GetState) => {
  const day = getCalendarDays(getState().calendarLayout.weeks).find(d => d.timestamp === timestamp)

  dispatch({
    type: OPEN_MONTHLY_CALENDAR_POPUP,
    payload: {
      date: {
        week: day.weekIndex,
        weekDayIndex: day.weekDayIndex,
        timestamp: day.timestamp,
      },
    },
  })
}

export const closeMonthlyCalendarPopup = () => ({type: CLOSE_MONTHLY_CALENDAR_POPUP})

export const openMonthlyCalendarEvent = (eventId: string) => (dispatch, getState: GetState) => {
  const day = getCalendarDays(getState().calendarLayout.weeks).find(d => d.events.find(event => event.id === eventId))

  dispatch({
    type: OPEN_MONTHLY_CALENDAR_EVENT,
    payload: {
      eventId,
      date: {
        week: day.weekIndex,
        weekDayIndex: day.weekDayIndex,
        timestamp: day.timestamp,
      },
    },
  })
}

export const closeMonthlyCalendarEvent = () => ({
  type: CLOSE_MONTHLY_CALENDAR_EVENT,
})

export const openAnyEventDetails = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const referenceDate = getReferenceDate(state)

  const anyMonthEvent = getEvents(state).find(
    event => event.scheduling.config.startDate && referenceDate.isSame(getStartDate(event), 'month'),
  )

  if (anyMonthEvent) {
    dispatch(openMonthlyCalendarEvent(anyMonthEvent.id))
  }
}

export const updateCalendarWeeks = (
  weeks: CalendarMonth,
  fullMonthText: string,
  referenceDate: number,
  weekDayNames: WEEKDAY[],
) => ({
  type: INIT_CALENDAR,
  payload: {
    weeks,
    fullMonthText,
    referenceDate,
    weekDayNames,
  },
})

export const initCalendarLayout = (referenceDate?: Moment) => (dispatch: Function, getState: GetState) => {
  const state = getState()
  referenceDate = referenceDate || getReferenceDate(state)
  const calendarWeekStartDay = getCalendarWeekStartDay(getComponentConfig(state))
  const locale = getFullLocale(state)
  const calendarWeeks = getCalendarWeeksWithEvents(state, referenceDate, calendarWeekStartDay)
  const fullMonthText = getFullMonthText(referenceDate.toDate(), locale)
  const weekDayNames = getWeekDayNames(locale, calendarWeekStartDay)

  dispatch(updateCalendarWeeks(calendarWeeks, fullMonthText, referenceDate.toDate().valueOf(), weekDayNames))
}

export const openAnyEventList = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const dayWithMoreThanOneEvent = getCalendarDaysWithMultipleEvents(state.calendarLayout)[0]
  const dayWithOneEvent = getCalendarDaysWithOneEvent(state.calendarLayout)[0]

  if (dayWithMoreThanOneEvent) {
    dispatch(openMonthlyCalendarPopup(dayWithMoreThanOneEvent.timestamp))
  } else if (dayWithOneEvent) {
    dispatch(openMonthlyCalendarPopup(dayWithOneEvent.timestamp))
  }
}

export const calendarSettingsTabChanged = (tab: TAB) => {
  if (tab === TAB.DISPLAY) {
    return openAnyEventDetails()
  } else {
    return closeMonthlyCalendarPopup()
  }
}

export const calendarSettingsSectionChanged = (id: DesignSection) => {
  if ([DesignSection.EVENT_DETAILS_POPUP, DesignSection.BUTTONS, DesignSection.RIBBONS].includes(id)) {
    return openAnyEventDetails()
  } else if (id === DesignSection.EVENT_LIST_POPUP) {
    return openAnyEventList()
  } else {
    return closeMonthlyCalendarPopup()
  }
}

export const calendarTextsSectionChanged = (id: TextSection) => {
  if ([TextSection.RSVP_CLOSED, TextSection.RSVP].includes(id)) {
    return openAnyEventDetails()
  } else {
    return closeMonthlyCalendarPopup()
  }
}

const getGroupedEventsByDate = (state: State) => {
  const eventsMap: {[timestamp: string]: wix.events.Event[]} = {}

  getEvents(state).forEach(event => {
    if (event.scheduling.config.startDate) {
      const startDate = getStartDate(event)

      const startDateTimestamp = startDate.startOf('day').valueOf()

      eventsMap[startDateTimestamp] = eventsMap[startDateTimestamp] || []
      eventsMap[startDateTimestamp].push(event)
    }
  })

  return eventsMap
}

const getCalendarWeeksWithEvents = (state: State, referenceDate: Moment, weekStartDay: WEEKDAY): CalendarMonth => {
  const eventsMap = getGroupedEventsByDate(state)

  const locale = getFullLocale(state)
  const monthStartDay = referenceDate.clone().startOf('month')
  const monthStartWeekDay = monthStartDay.day()

  let iteratedDate = monthStartDay
    .clone()
    .add(
      monthStartWeekDay >= weekStartDay ? weekStartDay - monthStartWeekDay : weekStartDay - monthStartWeekDay - 7,
      'days',
    )

  const calendarWeeks: CalendarMonth = []
  for (let week = 1; week <= 6; week++) {
    const calendarWeek: CalendarWeek = []
    for (let weekDay = 1; weekDay <= WEEK_LENGTH; weekDay++) {
      const timestamp = iteratedDate.toDate().valueOf()
      calendarWeek.push({
        events: eventsMap[timestamp] || [],
        dayMonthText: getDayMonthText(new Date(timestamp), locale),
        timestamp,
        weekDay: iteratedDate.day(),
        weekIndex: week - 1,
        weekDayIndex: weekDay - 1,
        isCurrentMonth: monthStartDay.isSame(iteratedDate, 'month'),
        today: iteratedDate.isSame(moment(), 'day'),
        past: iteratedDate.isBefore(moment()),
        dayNumber: iteratedDate.date(),
      })
      iteratedDate = iteratedDate.clone().add(1, 'day')
    }

    calendarWeeks.push(calendarWeek)
  }

  return calendarWeeks
}

const getReferenceDate = (state: State): Moment => {
  const {siteIsTemplate} = state.instance

  if (state.calendarLayout.referenceDate) {
    return moment(state.calendarLayout.referenceDate)
  } else if (isMobile(state)) {
    return getMobileShowcaseReferenceDate(state)
  } else if (isEditor(state)) {
    return getShowcaseReferenceDate(state)
  } else if (siteIsTemplate) {
    return getShowcaseReferenceDate(state, true)
  } else {
    return moment().startOf('day')
  }
}

const getShowcaseReferenceDate = (state: State, anyEventIsEnough = false): Moment => {
  const now = moment().startOf('day')

  const groupedEvents = Object.entries(getGroupedEventsByDate(state))
  const groupedEventsFromToday = groupedEvents.filter(([timestamp]) =>
    moment(Number(timestamp)).isSameOrAfter(moment().startOf('day')),
  )
  const groupedEventsTillToday = groupedEvents
    .filter(([timestamp]) => moment(Number(timestamp)).isBefore(moment().startOf('day')))
    .reverse()

  const multipleEventsInDay = ([, dayEvents]) => dayEvents.length > 1
  const anyEvent = ([, dayEvents]) => Boolean(dayEvents.length)

  const searches = []

  if (!anyEventIsEnough) {
    const multipleEventSearches = [
      () => groupedEventsFromToday.find(multipleEventsInDay),
      () => groupedEventsTillToday.find(multipleEventsInDay),
    ]

    multipleEventSearches.forEach(search => searches.push(search))
  }

  const anyEventSearches = [() => groupedEventsFromToday.find(anyEvent), () => groupedEventsTillToday.find(anyEvent)]

  anyEventSearches.forEach(search => searches.push(search))

  return (
    searches.reduce((referenceDate, search) => {
      if (!referenceDate) {
        const eventsEntry = search()
        return eventsEntry && moment(Number(eventsEntry[0])).startOf('day')
      } else {
        return referenceDate
      }
    }, null as Moment) || now
  )
}

const getMobileShowcaseReferenceDate = (state: State): Moment => {
  const now = moment().startOf('day')

  const upcomingDateWithEvents = Object.entries(getGroupedEventsByDate(state))
    .filter(([timestamp]) => moment(Number(timestamp)).isSameOrAfter(now))
    .find(([, dayEvents]) => Boolean(dayEvents.length))

  return upcomingDateWithEvents ? moment(Number(upcomingDateWithEvents[0])).startOf('day') : now
}

const getStartDate = (event: wix.events.Event) => {
  const {
    scheduling: {
      config: {startDate, timeZoneId},
    },
  } = event
  return moment(moment.tz(startDate, timeZoneId).format('YYYY-MM-DD HH:mm'))
}
