import {getFullLocale} from '../selectors/environment'
import {eventsToDates} from '../services/dates'
import {CommonDates, DatesState, GetCommonState} from '../types/state'

export const UPDATE_DATES = 'UPDATE_DATES'

interface updateDatesProps {
  commonDates?: CommonDates
  eventsList?: wix.events.Event[]
  locale?: string
}

export const updateDates = ({commonDates = {}, eventsList = [], locale: localeOverride}: updateDatesProps) => (
  dispatch: Function,
  getState: GetCommonState,
) => {
  const state = getState()
  const locale = localeOverride || getFullLocale(state)
  const events = eventsToDates(eventsList, locale)

  const payload: DatesState = {
    events,
    common: {
      ...commonDates,
    },
  }

  dispatch({
    type: UPDATE_DATES,
    payload,
  })
}
