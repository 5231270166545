import {combineReducers} from 'redux'
import {dates} from '../../commons/reducers/dates'
import {demoEvents} from '../../commons/reducers/demo-events'
import {environment} from '../../commons/reducers/environment'
import {experiments} from '../../commons/reducers/experiments'
import {multilingual} from '../../commons/reducers/multilingual'
import {siteSettings} from '../../commons/reducers/site-settings'
import {State} from '../types/state'
import {instance} from '../../commons/reducers/instance'
import {calendarLayout} from './calendar-layout'
import {component} from './component'
import {events} from './events'
import {layout} from './layout'
import {memberLoggedIn} from './member-logged-in'
import {membersEnabled} from './members-enabled'
import {needsResize} from './needs-resize'
import {owner} from './owner'

export default combineReducers<State>({
  events,
  component,
  siteSettings,
  environment,
  layout,
  calendarLayout,
  demoEvents,
  experiments,
  membersEnabled,
  multilingual,
  instance,
  needsResize,
  owner,
  memberLoggedIn,
  dates,
})
