import {
  BI_ENDPOINTS,
  BI_ORIGINS,
  EventMap,
  MembersModal,
  WIDGET_TYPE,
  LIST_LAYOUT,
} from '@wix/wix-events-commons-statics'
import {AnyAction} from 'redux'
import {isDetailsPageEnabled} from '../../commons/selectors/site-settings'
import {MODAL_OPENED} from '../../commons/services/modal'
import {SHARE_EVENT} from '../actions/event'
import {WIDGET_LOADED} from '../actions/layout'
import {NAVIGATE_TO_PAGE} from '../actions/navigate-to-page'
import {State} from '../types/state'

export const eventMap: EventMap = {
  [NAVIGATE_TO_PAGE]: (state: State, action: AnyAction) => ({
    evid: 503,
    event_id: action.payload.event.id,
    destination_type: isDetailsPageEnabled(state.siteSettings) ? 'page_details' : 'page_form',
  }),
  [WIDGET_LOADED]: (state: State, {isHomePage}) => {
    const {widgetType, listShowImage, showImage, listLayout} = state.component.settings
    const single = widgetType === WIDGET_TYPE.SINGLE
    const display_type = single ? 'SINGLE' : 'LIST'

    const has_image = single ? showImage : listShowImage

    const layout = single ? 'SINGLE' : getListLayoutName(listLayout)

    return {
      evid: 80,
      has_image,
      layout,
      is_main_page: isHomePage,
      display_type,
    }
  },
  [MODAL_OPENED]: (state: State, action: AnyAction) => {
    if (action.modalType === MembersModal.LIST) {
      return {
        evid: 89,
        event_id: action.params.eventId,
      }
    }
  },
  [SHARE_EVENT]: (state: State, action: AnyAction) => ({
    evid: 513,
    event_id: action.payload.eventId,
    button_name: action.payload.buttonName,
    origin: BI_ORIGINS.WIDGET,
  }),
  endpoint: BI_ENDPOINTS.EVENTS_UOU,
}

const getListLayoutName = listLayout =>
  Object.entries(LIST_LAYOUT)
    .find(([, value]) => value === listLayout)
    .shift()
