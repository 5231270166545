import {getYearMonthDay} from '@wix/events-moment-commons'
import {getLocale} from '@wix/wix-events-commons-statics'
import {SET_BASE_ENVIRONMENT} from '../../commons/actions/environment'
import {getFullLocale} from '../../commons/selectors/environment'
import {CommonDates} from '../../commons/types/state'
import {updateDates} from '../../commons/actions/dates'
import {GET_ORDER, PLACE_ORDER, UPDATE_ORDER} from '../actions/placed-order'
import {State} from '../types'

export const datesMiddleware = store => next => action => {
  switch (action.type) {
    case SET_BASE_ENVIRONMENT: {
      const state: State = store.getState()
      const locale = getLocale(action.payload.locale)
      const events = [state.event]
      const common = {
        nowYearMonthDayFormatted: getYearMonthDay(new Date().toString(), '', locale),
      }
      store.dispatch(updateDates({commonDates: common, eventsList: events, locale}))
      break
    }
    case PLACE_ORDER.SUCCESS:
    case UPDATE_ORDER.SUCCESS:
    case GET_ORDER.SUCCESS: {
      const state: State = store.getState()
      const locale = getFullLocale(state)
      const order = action.payload.order

      const dates: CommonDates = {
        formattedOrderCreatedDate: getYearMonthDay(order.created, '', locale),
      }
      store.dispatch(updateDates({commonDates: dates}))
      break
    }
    default:
      break
  }
  next(action)
}
